import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { Organization } from 'src/app/shared/models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private readonly http: HttpClient) { }

  private endpointUrl = '/api/organizations';

  private currentOrganization: Organization;
  currentOrganizationChanged = new Subject<Organization>();

  getCurrentOrganization():Organization {
    return this.currentOrganization;
  }

  setCurrentOrganization(organization:Organization) {
    this.currentOrganization = organization;
    this.currentOrganizationChanged.next(organization);
  }

  getOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.endpointUrl);
  }

  getOrganizationById(organizationId: number): Observable<Organization> {
    return this.http.get<Organization>(this.endpointUrl + `/${organizationId}`);
  }

  createOrganization(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>(this.endpointUrl, organization);
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(this.endpointUrl, organization);
  }

  deleteOrganization(organizationId: number): Observable<void> {
    const url = this.endpointUrl + '/' + organizationId;
    return this.http.delete<void>(url);
  }
}
