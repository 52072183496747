// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

window.env = window.env || {};

export const environment = {
  production: false,
  appUrl: window.env.appUrl || 'http://localhost:4200',
  adminGroupName: window.env.adminGroup || 'QAAdmins',
  eventManagerGroupName: window.env.eventManagerGroup || 'QAEventManagers',
  randomCoffeeImagesS3Bucket: window.env.s3Bucket || 'randomcoffee-images-qa',
  staticContentUrl: window.env.cloudfront || 'https://d25dgyzpkfrfv1.cloudfront.net/',
  adminPortal: window.location.origin === window.env.adminPortalUrl,
  authenticationSettings: {
    // Cognito Identity Pool ID
    identityPoolId: 'us-east-2:01dffddf-17e3-426d-860a-cbe2168fcc12',
    // Cognito Region
    region: 'us-east-2',
    // Cognito User Pool ID
    userPoolId: 'us-east-2_u1pt8wVG3',
    // Cognito Web Client ID
    userPoolWebClientId: window.env.webClientId || '3ak54e65nfterd4c381bs6kdig',
    // Hosted UI configuration
    oauth: {
      domain: 'randomcoffee.auth.us-east-2.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin === window.env.adminPortalUrl ? window.env.adminPortalUrl + '/' : (window.env.appUrl || 'http://localhost:4200') + '/user',
      redirectSignOut: window.location.origin === window.env.adminPortalUrl ? window.env.adminPortalUrl + '/' :  (window.env.appUrl || 'http://localhost:4200') + '/',
      responseType: 'code'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// Added QA App Client to AWS
// Needed to Update run_random_coffee_frontend.sh on Ec2
// Added App Client ID without a secret to AWS Authentication Providers on the Random Coffee Identity Pool
