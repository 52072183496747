import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventDetails } from 'src/app/shared/interfaces';
import {EventModel} from "../../models/event-model";

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private readonly http: HttpClient) {}
  activeEventChanged: Subject<void> = new Subject();
  dashboardEventChanged: Subject<void> = new Subject();
  private endpointUrl = '/api/events';

  getActiveEvents(): Observable<EventDetails[]> {
    return this.http.get<EventDetails[]>(this.endpointUrl + '/active');
  }

  getEventCount(): Observable<number> {
    return this.http.get<number>(this.endpointUrl + '/count');
  }

  getPastEventDetails(eventIDsList?: string[]): Observable<EventDetails[]> {
    let targetURL =  this.endpointUrl + `/pastdetails`;
    if(eventIDsList) {
      let _eventIds = eventIDsList.join(',');
      targetURL += "?eventIds=" + _eventIds;
    }
    return this.http.get<EventDetails[]>(targetURL);
  }

  /**
   * get the events for User depending on the parameters
   *
   * @example this.getEvents(1, {isOpen: true, isActive: false})
   * @param {number} organizationID : Identifier for events within organization
   * @param {object} settings : Object that contains optional settings for filtering events like "isOpen" and "isActive"
   * @public
   */

  getEvents(organizationID?: number, settings?: object): Observable<EventModel[]> {
    let targetURL = this.endpointUrl;
    let queryParams = new HttpParams();
    for (const [key, value] of Object.entries(settings ? settings : [])) {
      queryParams = queryParams.append(key, value.toString());
    }
    if(organizationID) {
      targetURL += "/" + organizationID;
    }
    return this.http.get<EventModel[]>(targetURL, {params: queryParams});
  }

  createEvent(event: EventModel): Observable<EventModel> {
    return this.http.post<EventModel>(this.endpointUrl + '/create', event);
  }

  createEventAsSuperAdmin(event: EventModel, organizationId: number): Observable<EventModel> {
    return this.http.post<EventModel>(this.endpointUrl + '/createEventAsAdmin?organizationId=' + organizationId, event);
  }

  closeEvent(eventId: number): Observable<void> {
    const url = this.endpointUrl + '/close?event=' + eventId;
    return this.http.post<void>(url, '{}');
  }

  cancelEvent(eventId: number, email: string, comment: string): Observable<void> {
    const url = `${this.endpointUrl}/cancel?event=${eventId}&email=${email}&comment=${comment}`;
    return this.http.post<void>(url, '{}');
  }

  finishEvent(eventId: number): Observable<void> {
    const url = this.endpointUrl + '/finish?event=' + eventId;
    return this.http.post<void>(url, '{}');
  }
}
