import { Component} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SupportTicket } from 'src/app/shared/interfaces/support-ticket';
import { SupportService } from 'src/app/shared/services/support/support.service';


@Component({
  selector: 'rc-support-modal',
  templateUrl: './supportModal.component.html',
  styleUrls: ['../nav/nav.component.scss']
})

export class SupportModalComponent {
  closeResult = '';
  showValidation = false;
  showFileAlert = false;
  maxFileSize = 30000000;
  modalOption: NgbModalOptions = null;

  supportTicketForm = new FormGroup({
    supportSummary: new FormControl('', Validators.required),
    supportDescription: new FormControl('', Validators.required),
    supportEventName: new FormControl(''),
    supportEmailAddress: new FormControl('', [Validators.required, Validators.email]),
    supportPriority: new FormControl('low'),
    supportAttachment: new FormControl('No File Chosen')
  });

  get supportSummary(): any { return this.supportTicketForm.get('supportSummary'); }
  get supportDescription(): any { return this.supportTicketForm.get('supportDescription'); }
  get supportEventName(): any { return this.supportTicketForm.get('supportEventName'); }
  get supportEmailAddress(): any { return this.supportTicketForm.get('supportEmailAddress'); }
  get supportPriority(): any { return this.supportTicketForm.get('supportPriority'); }
  get supportAttachment(): any { return this.supportTicketForm.get('supportAttachment'); }

  constructor(private modalService: NgbModal, private supportService: SupportService) {
  }
  selectedFile: File;
  public summaryValidationCheck(): boolean {
    if (this.supportSummary.invalid && (this.supportSummary.dirty || this.supportSummary.touched || this.showValidation)) {
      return true;
    }
    return false;
  }

  public supportDescriptionValidationCheck(): boolean {
    if (this.supportDescription.invalid && (this.supportDescription.dirty || this.supportDescription.touched || this.showValidation)) {
      return true;
    }
    return false;
  }

  public supportEmailAddressValidationCheck(): boolean {
    if (this.supportEmailAddress.invalid && (this.supportEmailAddress.dirty || this.supportEmailAddress.touched || this.showValidation)) {
      return true;
    }
    return false;
  }

  public onFileChanged(event: any): void {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile.size >= 30000000) {
      this.showFileAlert = true;
    } else {
      this.supportTicketForm.get('supportAttachment').setValue(this.selectedFile);
      this.showFileAlert = false;
    }
  }

  public onFileReset(): void {
    this.showFileAlert = false;
    this.supportTicketForm.get('supportAttachment').setValue('');
  }

  close(): void {
    this.modalService.dismissAll();
    this.supportTicketForm.reset({supportPriority: 'low'});
    this.showValidation = false;
    this.showFileAlert = false;
  }

  open(content: any): boolean {
    this.modalService.open(content, {centered: true, size: 'lg', backdrop: 'static', keyboard: false});

    return false; // modal will reload site if open function doesn't return false; see https://stackoverflow.com/a/1291944
  }

  formConverter(supportTicketForm: FormGroup): SupportTicket {
    return {
        supportSummary : supportTicketForm.value.supportSummary,
        supportDescription : supportTicketForm.value.supportDescription,
        supportEventName : supportTicketForm.value.supportEventName,
        supportEmailAddress : supportTicketForm.value.supportEmailAddress,
        supportPriority : supportTicketForm.value.supportPriority,
    } as SupportTicket;
  }
  async sendSupportTicket(): Promise<void> {
    if (this.supportTicketForm.status === 'VALID' && !this.showFileAlert) {
      const convertedTicket = this.formConverter(this.supportTicketForm);
      const supportAttachment = this.supportTicketForm.value.supportAttachment;
      this.supportService.sendSupportRequest(convertedTicket, supportAttachment);
      this.modalService.dismissAll(this.closeResult);
      this.supportTicketForm.reset({supportPriority: 'low'});
    } else {
       this.showValidation = true;
    }
  }
}
