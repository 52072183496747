import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, OrganizationService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { adminRoutes } from 'src/app/admin-routs';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Organization } from 'src/app/shared/models/organization';

@Component({
  selector: 'rc-root',
  template: `
    <div class="main" [attr.style]="getCustomStyle()">
      <rc-banner class="d-flex banner"></rc-banner>
      <rc-nav class="row"  *ngIf="showBar"></rc-nav>
      <div class="justify-content-sm-center">
        <router-outlet></router-outlet>
      </div>
    </div>
    <rc-footer *ngIf="testFlag"></rc-footer>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  // temporary test flag for a new footer
  testFlag = localStorage.getItem('test');
  showBar = false;
  primaryColor = null as string;
  secondaryColor = null as string;
  bgColor = null as string;
  fontMainColor = null as string;
  currentOrganizationChanged$: Subscription;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private organizationService: OrganizationService
  ) {
  }

  ngOnInit(): void {
    this.currentOrganizationChanged$ = this.organizationService.currentOrganizationChanged.subscribe((organization: Organization) => {
      this.primaryColor = organization.primaryColor ? organization.primaryColor : this.primaryColor;
      this.secondaryColor = organization.secondaryColor ? organization.secondaryColor : this.secondaryColor;
      this.bgColor = organization.bgColor ? organization.bgColor : this.bgColor;
      this.fontMainColor = organization.fontMainColor ? organization.fontMainColor : this.fontMainColor;
      document.body.style.setProperty('--bg-color', this.bgColor);
      document.body.style.setProperty('--primary-color', this.primaryColor);
      document.body.style.setProperty('--secondary-color', this.secondaryColor);
      document.body.style.setProperty('--font-color-main', this.fontMainColor);
    })
    this.showVisibilityNavBar();
    if (environment.adminPortal) {
      this.router.resetConfig(adminRoutes)
    }

  }

  ngOnDestroy(): void {
    this.currentOrganizationChanged$.unsubscribe();
  }

  getCustomStyle(): SafeStyle {
    let styleString: string = '';
    if (this.primaryColor && this.secondaryColor && this.bgColor && this.fontMainColor) {
      styleString = `--primary-color:${this.primaryColor}; --secondary-color:${this.secondaryColor}; --bg-color:${this.bgColor}; --font-color-main:${this.fontMainColor};`
    }
    return this.sanitizer.bypassSecurityTrustStyle(styleString);
  }
  showVisibilityNavBar(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      if (currentUser !== null) {
        this.showBar = true;
        this.organizationService.setCurrentOrganization(currentUser.organization);
      }
    });
  }

}
