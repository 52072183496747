import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeAll, mergeMap, tap, toArray } from 'rxjs/operators';
import { CognitoUser, UserRole } from 'src/app/shared/interfaces';
import { ParticipantService } from 'src/app/shared/services/participant/participant.service';
import { StaticContentService } from 'src/app/shared/services/static-content/static-content.service';
import { environment } from 'src/environments/environment';
import { OrganizationService } from "../organizations/organizations.service";
import { User } from "../../models/user";
import { HttpErrorResponse } from "@angular/common/http";
import { Participant } from "../../models/participant";
import { Organization } from "../../models/organization";
import { ErrorService } from 'src/app/shared/services/error/error-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private auth = Auth;
  private currentUser: User;
  currentUserChanged = new Subject<User>();
  userProfileUpdated = new Subject<User>();

  constructor(
    private readonly participantService: ParticipantService,
    private readonly organizationService: OrganizationService,
    private staticContentService: StaticContentService,
    private readonly errorService: ErrorService,
  ) {
  }

  /**
   * Gets the current Cognito User from AWS
   * @example this.getCurrentCognitoUser(true).then(updatedCognitoUser => ...)
   *
   * @param {boolean} bypassCache?: optional property for using cache value for Cognito User, set true in case we need updated User information
   * @returns The current user Cognito User from AWS
   * @public
   */
  getCurrentCognitoUser(bypassCache?: boolean): Promise<CognitoUser> {
    return this.auth.currentAuthenticatedUser({
      bypassCache: bypassCache  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
  }

  loadCurrentUser(): Observable<User> {
    let userObject: User;
    const getUserInformation$ = from(this.getCurrentCognitoUser(true)).pipe(
      mergeMap(cognitoUser =>
        forkJoin(
          this.organizationService.getOrganizationById(cognitoUser.attributes["custom:organization_id"]),
          this.participantService.getByEmail(cognitoUser.attributes.email).pipe(catchError(err => of(err as HttpErrorResponse))),
          of(cognitoUser)
        ),
      )
    )

    return getUserInformation$.pipe(
      map(([userOrganization, participant, cognitoUser]) => {
        const roles = cognitoUser.signInUserSession.getAccessToken().decodePayload()['cognito:groups'] || [] as string[];
        // TODO: create an additional message for new Users
        // 'As a new User you need to fill the registration Form to continue'
        if (participant instanceof HttpErrorResponse) {
          userObject = new User(cognitoUser.username, null, null, cognitoUser.attributes.email, cognitoUser.attributes["custom:organization_id"])
        } else {
          participant = participant as Participant;
          userObject = new User(cognitoUser.username, participant.firstName, participant.lastName, participant.email)
          userObject.roles = roles;
          userObject.isAdmin = roles.findIndex(group => group === environment.adminGroupName) !== -1;
          userObject.participantId = participant ? participant.participantId : 0;
          userObject.locationId = participant ? participant.locationId : 0;
          userObject.phoneNumber = participant ? participant.phoneNumber : '';
          userObject.volunteer = participant ? participant.volunteer : false;
          userObject.about = participant ? participant.about : '';
          userObject.location = participant ? participant.location : '';
          userObject.department = participant ? participant.department : '';
          userObject.role = participant ? participant.role : '';
          userObject.companyDuration = participant ? participant.companyDuration : '';
          userObject.contactOption = participant ? participant.contactOption : '';
          userObject.organizationId = userOrganization ? userOrganization.organizationId : 1;
          userObject.organization = userOrganization ? userOrganization : undefined as Organization;
          this.staticContentService.setStaticContentUrl(userOrganization.s3BucketPath);
        }
        this.currentUser = userObject;
        this.currentUserChanged.next(userObject);
        return userObject;
      }),
    );
  }

  public getUserData(): User {
    return this.currentUser;
  }

  public updateUserProfile(participant: Participant): void {
    this.participantService.updateParticipant(participant).subscribe(participant => {

      // @ts-ignore
      this.currentUser = {
        ...this.currentUser,
        ...participant
      };
      this.userProfileUpdated.next(this.currentUser);
    }, () => this.errorService.setErrorMessage("There is an error trying to update profile!"))
  }

  /**
   * Gets the current signed-inUser with all necessary information for App
   * @returns The current user, if none is found an error is thrown
   */
  getCurrentUser(): Observable<User> {
    return this.currentUser ? of(this.currentUser) : this.loadCurrentUser();
  }

  /**
   * Gets the current access token
   * @returns The current access token, if the token is not found an error is thrown
   */
  getAccessToken(): Observable<string> {
    return from(this.auth.currentSession()).pipe(
      map(session => session.getAccessToken().getJwtToken())
    );
  }

  /**
   * Gets the current admin status of the user
   * @returns True if the user is an admin, otherwise false
   */
  isAdmin(): Observable<boolean> {
    return from(this.auth.currentSession()).pipe(
      map(session => (session.getAccessToken().decodePayload()['cognito:groups'] as string[]).findIndex(group => group === environment.adminGroupName) !== -1),
      catchError(() => of(false)),
    );
  }

  /**
   * Gets the current authenticated status of the user
   * @returns True if the user is authenticated, otherwise false
   */
  isAuthenticated(): Observable<boolean> {
    return from(this.getCurrentCognitoUser()).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  /**
   * Redirects to the cognito sign in page
   */
  cognitoSignIn(): void {
    this.auth.federatedSignIn();
  }

  /**
   * Logs the user out, triggering a redirect to the cognito logout url.
   *
   * <p>Cognito signout() returns a Promise, so without the then() call, it won't really run, and for some reason the
   * whole method was ignored if I didn't add a catch() call. I threw the catch error into local storage, because
   * writing it to the console is a waste since the page immediately refreshes. I could have thrown an alert but that
   * interrupts the user's flow. This way we have *some* way to record the error and maybe retrieve it later.
   */
  logout(): void {
    this.auth.signOut().then().catch(ex => localStorage.setItem('rc-signout-failure', JSON.stringify(ex)));
  }

  /**
   * Gets the current user's roles
   */
  getRoles(): Observable<UserRole[]> {
    let _organizationId: string;
    let cognitoGroups: string[];
    return from(this.auth.currentSession()).pipe(
      tap(session => {
        _organizationId = session.getIdToken().decodePayload()["custom:organization_id"];
        cognitoGroups = session.getAccessToken().decodePayload()['cognito:groups'] ? session.getAccessToken().decodePayload()['cognito:groups'] : ['User'];
      }),
      mergeMap(() => cognitoGroups.map(
        role => {
          switch (role) {
            case environment.adminGroupName: {
              return _organizationId && _organizationId === "1" ? [UserRole.SuperAdmin] : [UserRole.Admin];
            }
            case environment.eventManagerGroupName: {
              return [UserRole.EventManager];
            }
            default:
              return [UserRole.User]
          }
        })),
      mergeAll(),
      toArray(),
      catchError((err) => {
        return of([UserRole.Unknown])
      }),
    );
  }
}
