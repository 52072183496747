import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private readonly http: HttpClient) { }

  private endpointUrl = '/api/subscriptions';

  getSubscription(participantId: number, eventId: number): Observable<Subscription> {
    const param: any = {
      eventId,
      participantId
    };
    return this.http.get<Subscription>(this.endpointUrl, { params: param });
  }

  getSubscriptions(participantId?: number, eventId?: number): Observable<Subscription[]> {
    const param: any = {};
    if (eventId) {
      param.eventId = eventId;
    }
    if (participantId) {
      param.participantId = participantId;
    }
    return this.http.get<Subscription[]>(this.endpointUrl, { params: param });
  }

  createSubscription(participantId: number, eventId: number): Observable<Subscription> {
    const body: any = {};
    if (eventId) {
      body.eventId = eventId;
    }
    if (participantId) {
      body.participantId = participantId;
    }
    return this.http.post<Subscription>(this.endpointUrl + '/subscribe', body);
  }

  deleteSubscription(subscriptionId: number): Observable<void> {
    return this.http.delete<void>(this.endpointUrl + `/unsubscribe/${subscriptionId}`);
  }

  getSubscriptionCountTotal(): Observable<number> {
    return this.http.get<number>(this.endpointUrl + `/count`);
  }
}
