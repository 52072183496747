import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticatedGuard implements CanLoad {
  constructor(private readonly authenticationService: AuthenticationService) { }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.isAuthenticated().pipe(
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.authenticationService.cognitoSignIn();
        }
      })
    );
  }
}
