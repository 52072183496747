import { Component } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rc-about-modal',
  templateUrl: './aboutModal.component.html',
  styleUrls: ['../nav/nav.component.scss']
})
export class AboutModalComponent {
  closeResult = '';

  constructor(private modalService: NgbModal) {}

  open(content: any): boolean {
    this.modalService.open(content, { centered: true, size: 'lg' });

    return false; // modal will reload site if open function doesn't return false; see https://stackoverflow.com/a/1291944
  }
}
