import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserRole } from 'src/app/shared/interfaces';
import { AuthenticationService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthenticatedGuard implements CanLoad {
  constructor(private readonly authenticationService: AuthenticationService,
              private readonly router: Router) { }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.getRoles().pipe(
      map(roles => roles.includes(UserRole.Admin) || roles.includes(UserRole.SuperAdmin)),
      tap(isAdmin => {
        if (!isAdmin) {
          this.router.navigateByUrl('/user');
        }
      }),
      catchError(() => {
        this.authenticationService.cognitoSignIn();
        return of(false);
      })
    );
  }
}
