import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { setupAuthenticationFactory } from 'src/app/app-initializers/setup-authentication-factory';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { HomeComponent } from 'src/app/home/home.component';
import { BannerComponent } from 'src/app/shared/components/banner/banner.component';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { AboutModalComponent } from 'src/app/shared/components/modal/aboutModal.component';
import { SupportModalComponent } from 'src/app/shared/components/support.modal/supportModal.component';
import { AuthenticationService, ParticipantService } from 'src/app/shared/services';
import { Confirm } from 'src/app/UI/confirm.component';
import { RequestInterceptor } from 'src/utils/RequestInterceptor';

import { NavComponent } from './shared/components/nav/nav.component';
import { UserAccessModule } from "./user-access-module/user-access-module";

@NgModule({
  declarations: [
    AppComponent,
    AboutModalComponent,
    BannerComponent,
    // ErrorFieldComponent,
    FooterComponent,
    SupportModalComponent,
    BannerComponent,
    HomeComponent,
    NavComponent,
    Confirm
  ],
  entryComponents: [Confirm],
  imports: [
    BrowserModule,
    NgbModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    CommonModule,
    ToastrModule.forRoot(),
    MatFormFieldModule,
    MatDialogModule,
    UserAccessModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupAuthenticationFactory,
      deps: [AuthenticationService, ParticipantService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
