import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSetting } from 'src/app/shared/interfaces/app-setting';
import { Organization } from 'src/app/shared/models/organization';
@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  constructor(private readonly http: HttpClient) { }
  private endpointUrl = '/api/settings';
  getAllUserInformationAppSettings(organization?: Organization): Observable<AppSetting[]> {
    if (organization && organization.organizationId) {
      return this.http.get<AppSetting[]>(`${this.endpointUrl}?organizationId=${organization.organizationId}`);
    } else return this.http.get<AppSetting[]>(this.endpointUrl);
  }
  getAllEnabledUserInformationSettings(organization?: Organization): Observable<AppSetting[]> {
    if (organization && organization.organizationId) {
      return this.http.get<AppSetting[]>(`${this.endpointUrl}?onlyEnabled=true&organizationId=${organization.organizationId}`);
    } else return this.http.get<AppSetting[]>(this.endpointUrl + '?onlyEnabled=true');
  }
  getAllEnabledAppSettings(organization?: Organization): Observable<AppSetting[]> {
    if (organization && organization.organizationId) {
      return this.http.get<AppSetting[]>(`${this.endpointUrl}?onlyEnabled=false&organizationId=${organization.organizationId}`);
    } else return this.http.get<AppSetting[]>(this.endpointUrl + '?onlyEnabled=false');
  }
  updateAppSettings(settings: AppSetting[], organization?: Organization): Promise<AppSetting[]> {
    if (organization && organization.organizationId) {
      return this.http.put<AppSetting[]>(`${this.endpointUrl}/update?organizationId=${organization.organizationId}`, settings).toPromise();
    } else return this.http.put<AppSetting[]>(this.endpointUrl + '/update', settings).toPromise();
  }
}
