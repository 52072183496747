import { Routes } from '@angular/router';
import { AdminAuthenticatedGuard } from 'src/app/shared/guards/admin-authenticated/admin-authenticated.guard';
import { EventsAuthenticatedGuard } from 'src/app/shared/guards/events-authenticated/events-authenticated.guard';
import { UserAuthenticatedGuard } from 'src/app/shared/guards/user-authenticated/user-authenticated.guard';

export const adminRoutes: Routes =  [
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canLoad: [UserAuthenticatedGuard],
  },
   {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [AdminAuthenticatedGuard],
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    canLoad: [EventsAuthenticatedGuard],
  },
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [AdminAuthenticatedGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

