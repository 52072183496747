import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/shared/interfaces';
import { AuthenticationService, LogoutService } from 'src/app/shared/services';

@Component({
  selector: 'rc-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [LogoutService, AuthenticationService]
})
export class NavComponent implements OnInit {
  UserRole = UserRole;
  private _roles: UserRole[];

  constructor(public readonly router: Router,
              private readonly logoutService: LogoutService,
              private readonly authenticationService: AuthenticationService ) { }

  ngOnInit(): void {
    this._roles = [];
    this.authenticationService.getRoles().subscribe(roles => {
      this._roles = roles;
    });

  }

  get roles(): UserRole[] {
    return this._roles;
  }

  logout(): void {
    this.logoutService.logout();
  }
}
