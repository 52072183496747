import {ActivatedRoute} from '@angular/router';
import {Injectable} from "@angular/core";
import { User } from "../../models/user";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private user:User;

  constructor(private readonly activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.user = data.user as User
    })
  }

  getUser(): User {
    return this.user;
  }

}
