import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StaticContentService } from 'src/app/shared/services';

@Component({
  selector: 'rc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  constructor(private readonly staticContentService: StaticContentService,
    public readonly router: Router) { }

  bannerImageUrl = this.staticContentService.getStaticContentUrl(StaticContentService.PAGE_BANNER_S3_FILE_NAME);
  ngOnInit(): void {
    this.staticContentService.staticContentUrlChanged$.subscribe(() => {
      this.bannerImageUrl = this.staticContentService.getStaticContentUrl(StaticContentService.PAGE_BANNER_S3_FILE_NAME);
    })
  }
}
