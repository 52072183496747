import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ErrorService {
    constructor() { };
    public errorMessageChanged = new Subject<string>();

    public setErrorMessage(message: string): void {
        this.errorMessageChanged.next(message);
    }
}