import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Meetup, MeetupByParticipant } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MeetupService {
  constructor(private readonly http: HttpClient) {}

  private endpointUrl = '/api/meetups';

  /**
   * Get all the meetups that have occurred.
   * We can filter by event id, to get only the meetups
   * that occurred at specific event.
   * @param eventId the id of the event
   */
  getAllMeetups(eventId?: number): Observable<Meetup[]> {
    const param: any = eventId ? { eventId } : {};
    return this.http.get<Meetup[]>(this.endpointUrl, {params: param});
  }

  /**
   * Complete meetup by a specific participant.
   * @param meetupId the id of the meetup
   */
  completeParticipantMeetup(meetupId: number): Observable<Meetup> {
    return this.http.post<Meetup>(this.endpointUrl + `/complete/${meetupId}`, {});
  }

  /**
   * Get all meetups by a specific participant.
   * We can filter by the event id.
   * @param participantId the participant id
   */
  getParticipantMeetups(participantId: number): Observable<MeetupByParticipant[]> {
    return this.http.get<MeetupByParticipant[]>(this.endpointUrl + `/participant/${participantId}`);
  }

  /**
   * Get information on specific meetup
   * @param participantId the participant id
   * @param meetupId the meetup id
   */
  getParticipantMeetup(participantId: number, meetupId: number): Observable<MeetupByParticipant> {
    return this.http.get<MeetupByParticipant[]>(this.endpointUrl + `/participant/${participantId}?meetupId=${meetupId}`).pipe(map(participantMeetups => participantMeetups[0]));
  }

  /**
   * Complete Meetup
   * @param meetupId the meetup id
   */
  completeMeetup(meetupId: number): Observable<Meetup> {
    return this.http.post<Meetup>(this.endpointUrl + `/complete/${meetupId}`, '');
  }

  /**
   * Get Completed Meetups
   */
  getCompleteMeetupsTotal(): Observable<number> {
    return this.http.get<number>(this.endpointUrl + `/count`);
  }
}
