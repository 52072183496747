import { Inject, Injectable, Optional } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';

@Injectable()
export class LogoutService {
  static LOGOUT_TIME_MIN = 15;
  static CHECK_INTERVAL = 10000;

  private intervalId;

  constructor(private readonly authenticationService: AuthenticationService,
              @Inject('lastActionKey') @Optional() private sessionVar?: string,
              @Inject('logoutPathUrl') @Optional() private logoutPath?: string) {
    this.sessionVar = sessionVar || 'lastAction';
    this.logoutPath = '/';
    this.setLogoutSessionVar(Date.now());
    this.initActivityListener();
    this.initCheckTimer();
  }

  /**
   * This will set the logout session variable to the last action
   *
   * @param lastAction number
   */
  private setLogoutSessionVar(lastAction: number): void {
    sessionStorage.setItem(this.sessionVar, lastAction.toString());
  }

  /**
   * Function to get the last value assigned to our logout session variable
   *
   * @returns number
   */
  private getLogoutSessionVar(): number {
    return Number.parseInt(sessionStorage.getItem(this.sessionVar), 10) || 0;
  }

  private clearLogoutSessionVar(): void {
    sessionStorage.clear();
  }

  /**
   * Function to listen for all events on the browser and reset timer
   * when the event could and does occur.
   */
  private initActivityListener(): void {
    document.body.addEventListener('click', () => this.resetTimer());
    document.body.addEventListener('mouseover', () => this.resetTimer());
    document.body.addEventListener('mouseout', () => this.resetTimer());
    document.body.addEventListener('keydown', () => this.resetTimer());
    document.body.addEventListener('keyup', () => this.resetTimer());
    document.body.addEventListener('keypress', () => this.resetTimer());
  }

  /**
   * Set the logout session variable to the current time
   */
  private resetTimer(): void {
    this.setLogoutSessionVar(Date.now());
  }

  /**
   * Setup interval that checks if we should be
   * logged out every CHECK_INTERVAL constructed
   */
  private initCheckTimer(): void {
    this.intervalId = setInterval(() => {
      this.checkLogout();
    }, LogoutService.CHECK_INTERVAL);
  }

  /**
   * Clear the timer that was previously setup
   * so that the other pages do not logout
   */
  private clearTimer(): void {
    clearInterval(this.intervalId);
  }

  private checkLogout(): void {
    const nowTime = Date.now();
    const timeLeft = this.getLogoutSessionVar() + (LogoutService.LOGOUT_TIME_MIN * 60000);
    const autoTimeout = (timeLeft - nowTime) <= 0;

    if (autoTimeout) {
      this.logout();
    }
  }

  logout(): void {
    this.clearTimer();
    this.clearLogoutSessionVar();
    this.authenticationService.logout();
  }
}
