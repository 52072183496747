import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SummaryReportEmail } from 'src/app/shared/interfaces';
import { Organization } from 'src/app/shared/models/organization';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private readonly http: HttpClient) { }

  private endpointUrl = '/api/reporting';

  reportEventAllMeetups(eventId: number): Observable<string> {
    const url = this.endpointUrl + '/meetups/download?eventId=' + eventId;
    return this.http.get(url, { responseType: 'text' });
  }

  reportAll(eventId: number, eventName: string): void {
    this.reportEventAllMeetups(eventId).subscribe((csvData) => {
      const blob = new Blob(['\ufeff' + csvData], {
        type: 'text/csv;charset=utf-8;',
      });
      const dwldLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const filename = 'Event_Report_' + eventId + '.csv';
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', filename);
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      window.location.reload();
    });
  }

  reportMonthly(organization?: Organization): void {
    this.reportPreviousMonthCompleted(organization).subscribe((csvData) => {
      const current = new Date();
      current.setMonth(current.getMonth() - 1);
      const previousMonth = current.toLocaleString('default', { month: 'long' });
      const blob = new Blob(['\ufeff' + csvData], {
        type: 'text/csv;charset=utf-8;'
      });
      const dwldLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const filename = previousMonth + '_Completed_Report.csv';
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', filename);
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    });
  }

  reportEventNewCompletions(eventId: number): Observable<string> {
    const url =
      this.endpointUrl +
      '/meetups/download?eventId=' +
      eventId +
      '&completed=true&newMeetups=true';
    return this.http.get(url, { responseType: 'text' });
  }

  reportPreviousMonthCompleted(organization?: Organization): Observable<string> {
    const url = `${this.endpointUrl}/summaryReport/monthlyReport`;
    if (organization && organization.organizationId) {
      return this.http.get(`${url}?organizationId=${organization.organizationId}`, { responseType: 'text' });
    } else return this.http.get(url, { responseType: 'text' });
  }

  saveEmailSummaryReportRecipients(emailRecipients: any): Observable<string> {
    const url = `${this.endpointUrl}/summaryReport/saveRecipients`;
    return this.http.post<any>(url, emailRecipients);
  }

  saveEmailSummaryReportRecipient(emailRecipient: SummaryReportEmail, organization?: Organization): Observable<SummaryReportEmail> {
    const url = `${this.endpointUrl}/recipient`;
    if (organization && organization.organizationId) {
      return this.http.post<any>(`${url}?organizationId=${organization.organizationId}`, { email: emailRecipient });
    } else return this.http.post<any>(url, { email: emailRecipient });
  }

  getRecipientEmails(organization?: Organization): any {
    const url = `${this.endpointUrl}/summaryReport/getRecipients`;
    if (organization && organization.organizationId) {
      return this.http.get<any>(`${url}?organizationId=${organization.organizationId}`, { responseType: 'json' });
    } else return this.http.get<any>(url, { responseType: 'json' });
  }

  removeRecipient(reportSummaryRecipientId: any): Observable<any> {
    const url = `${this.endpointUrl}/summaryReport/removeRecipients?reportSummaryRecipientId=${reportSummaryRecipientId}`;
    return this.http.post<any>(url, {});
  }
}
