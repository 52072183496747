import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ErrorService } from 'src/app/shared/services/error/error-service';
import { Organization } from 'src/app/shared/models/organization';
import { PutObjectRequest } from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class StaticContentService {
  static readonly TOGGLE_ON_S3_FILE_NAME = 'toggle-on.png';
  static readonly TOGGLE_OFF_S3_FILE_NAME = 'toggle-off.png';
  static readonly HOME_BACKGROUND_S3_FILE_NAME = 'cup-of-coffee.jpg';
  static readonly PAGE_BANNER_S3_FILE_NAME = 'page_banner_img.png';
  static readonly COMPANY_LOGO_S3_FILE_NAME = 'company_logo_img.png';
  static readonly EMAIL_HEADER_S3_FILE_NAME = 'email_header_img.png';
  static readonly EMAIL_FOOTER_S3_FILE_NAME = 'email_footer_img.png';
  static readonly LANDING_PAGE_STEPS_1_S3_FILE_NAME = 'Landing-page-steps-1.png';
  static readonly LANDING_PAGE_STEPS_2_S3_FILE_NAME = 'Landing-page-steps-2.png';
  static readonly LANDING_PAGE_STEPS_3_S3_FILE_NAME = 'Landing-page-steps-3.png';
  static readonly LANDING_PAGE_STEPS_4_S3_FILE_NAME = 'Landing-page-steps-4.png';
  static readonly LANDING_PAGE_STEPS_1_TXT_S3_FILE_NAME = 'Landing-page-steps-1-context.html';
  static readonly LANDING_PAGE_STEPS_2_TXT_S3_FILE_NAME = 'Landing-page-steps-2-context.html';
  static readonly LANDING_PAGE_STEPS_3_TXT_S3_FILE_NAME = 'Landing-page-steps-3-context.html';
  static readonly LANDING_PAGE_STEPS_4_TXT_S3_FILE_NAME = 'Landing-page-steps-4-context.html';
  static readonly LANDING_PAGE_INTRODUCTION_TXT_S3_FILE_NAME = 'Landing-page-introduction-context.html';
  static readonly LANDING_PAGE_SECONDARY_TXT_S3_FILE_NAME = 'Landing-page-secondary-context.html';
  static readonly LANDING_PAGE_SECTION_TXT_S3_FILE_NAME = 'Landing-page-section-context.html';
  static readonly LANDING_PAGE_INTRO_S3_FILE_NAME = 'Landing-page-intro.png';
  static readonly LANDING_PAGE_LOGO_S3_FILE_NAME = 'Landing-page-logo.png';
  static readonly MOBILE_SITE_LP_LOGO_S3_FILE_NAME = 'Mobile-Site-LP-Logo.png';
  static readonly WHITE_SITE_LP_LOGO_S3_FILE_NAME = 'White-site-lp-logo.png';
  static readonly MOBILE_WHITE_SITE_LP_LOGO_S3_FILE_NAME = 'Mobile-white-site-lp-logo.png';
  static readonly GLOBAL_FOOTER_S3_FILE_NAME = 'Global-footer.png';
  static readonly CUP_GLOBAL_FOOTER_S3_FILE_NAME = 'Cup-global-footer.png';
  static readonly MEETUP_EMAIL_CONTEXT_S3_FILE_NAME = 'meetupEmailContext.html';
  static readonly SUPPORT_EMAIL_CONTEXT_S3_FILE_NAME = 'supportTicketEmailContext.html';
  static readonly FINAL_EMAIL_CONTEXT_S3_FILE_NAME = 'finalEmailContext.html';
  static readonly DEFAULT_MEETUP_EMAIL_CONTEXT_S3_FILE_NAME = 'defaultMeetupEmailContext.html';
  static readonly DEFAULT_SUPPORT_EMAIL_CONTEXT_S3_FILE_NAME = 'defaultSupportTicketEmailContext.html';
  static readonly DEFAULT_FINAL_EMAIL_CONTEXT_S3_FILE_NAME = 'defaultFinalEmailContext.html';
  // teams icon - not available in fa icons
  static readonly TEAMS_ICON_S3_FILE_NAME = 'teams_icon.svg';
  static readonly MATCH_EMAIL_CONTEXT_S3_FILE_NAME = 'matchEmailContext.html';
  static readonly MATCH_EMAIL_HEADER_IMG_S3_FILE_NAME = 'matchEmailHeader.svg';
  static readonly MATCH_EMAIL_FOOTER_S3_FILE_NAME = 'matchEmailFooter.html';

  constructor(private readonly errorService: ErrorService) {
    this.configureAwsEnvironment();
  }

  staticContentUrlChanged$ = new Subject<String>();
  private companyFolder = '';
  private staticContentUrl = environment.staticContentUrl;
  private s3: AWS.S3;

  getFilePath(s3FileName: string, organization?: Organization): string {
    if (organization && organization.s3BucketPath) {
      return `${organization.s3BucketPath}/${s3FileName}`;
    } else return `${this.companyFolder}${s3FileName}`;
  }

  getStaticContentUrl(s3FileName: string, organization?: Organization): string {
    if (organization && organization.s3BucketPath) {
      return `${environment.staticContentUrl}${organization.s3BucketPath}/${s3FileName}`
    } else return this.staticContentUrl + s3FileName;
  }


  setStaticContentUrl(folder: String) {
    this.companyFolder = folder ? folder.toString() + '/' : "";
    this.staticContentUrl = `${environment.staticContentUrl}${this.companyFolder}`;
    this.staticContentUrlChanged$.next(this.staticContentUrl);
  }

  async getContextFile(fileName: string): Promise<string> {
    try {
      const params = {
        Bucket: environment.randomCoffeeImagesS3Bucket,
        Key: fileName
      };

      const data = await this.s3.getObject(params).promise();

      return data.Body.toString();
    } catch (e) {
      throw new Error(`Could not retrieve file from S3: ${e.message}`);
    }
  }

  uploadStaticContentToAWS(file: File, fileName: string, organization?: Organization): Promise<AWS.S3.ManagedUpload.SendData> {
    let s3Request: PutObjectRequest;
    if (organization && organization.s3BucketPath) {
      s3Request = {
        Key: organization.s3BucketPath + "/" + fileName,
        Bucket: environment.randomCoffeeImagesS3Bucket,
        Body: file
      };
    } else {
      s3Request = {
        Key: this.companyFolder + fileName,
        Bucket: environment.randomCoffeeImagesS3Bucket,
        Body: file
      };
    }

    return this.s3.upload(s3Request).promise();
  }

  private async checkFolderExists(folder: String): Promise<Boolean> {
    const s3Request = {
      Key: `${folder}/`,
      Bucket: environment.randomCoffeeImagesS3Bucket,
    };
    try {
      await this.s3.getObject(s3Request).promise();
      return true;
    } catch (err) {
      if (err.code === "NoSuchKey") {
        return false;
      } else {
        throw new Error(`The error trying to create folder in s3 bucket: ${err.message}`);
      }
    }
  }

  public async createS3Folder(folder: String): Promise<Object> {
    const s3Request = {
      Key: `${folder}/`,
      Bucket: environment.randomCoffeeImagesS3Bucket,
    };

    const isFolder = await this.checkFolderExists(folder);
    if (isFolder) {
      throw new Error(`The folder ${folder} probably exists in s3 Bucket!`);
    } else {
      return this.s3.putObject(s3Request).promise();
    }

  }

  private configureAwsEnvironment(): void {
    AWS.config.region = environment.authenticationSettings.region;

    // load credentials using AWS Cognito identity pool
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.authenticationSettings.identityPoolId,
    });

    this.s3 = new AWS.S3({
      accessKeyId: AWS.config.credentials.accessKeyId,
      secretAccessKey: AWS.config.credentials.secretAccessKey,
      params: { Bucket: environment.randomCoffeeImagesS3Bucket }
    });
  }
}
