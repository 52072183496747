import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneValidationService {
  constructor() {
  }

  isValidPhone(phoneNumber: string): boolean {
    if (phoneNumber !== null && phoneNumber !== '' && phoneNumber.length <= 15) {
        return true;
    }
    return false;
  }

  testCharacter(phoneNumber: string): boolean {
    return /[0-9 ()+-]/.test(phoneNumber);
  }
}
