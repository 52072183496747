import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private readonly http: HttpClient) { }

  private endpointUrl = `/api/email`;

  sendEmail(eventId: number): Observable<void> {
    return this.http.get<void>(`${this.endpointUrl}/sendEmail/${eventId}`);
  }

  sendFinalEmail(eventId: number): Observable<void> {
    return this.http.get<void>(`${this.endpointUrl}/sendFinalEmail/${eventId}`);
  }
}
