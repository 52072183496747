import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {User} from "../shared/models/user";
import { AuthenticationService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

export function setupAuthenticationFactory(authenticationService: AuthenticationService): () => Promise<User> {
  return () => {
    Amplify.configure(environment.authenticationSettings);
    Auth.configure(environment.authenticationSettings);
    return authenticationService.getCurrentUser().pipe(
      catchError(() => EMPTY),
    ).toPromise();
  };
}
