export class ImageUploadOption {
    file: File;
    sectionTitle: string;
    src: string;
    inputId: string;
    s3FileName: string;
    recommendedWidth: number;
    recommendedHeight: number;

    constructor(file: File, sectionTitle: string, src: string, inputId: string, s3FileName: string, recommendedWidth: number, recommendedHeight: number) {
        this.file = file;
        this.sectionTitle = sectionTitle;
        this.src = src;
        this.inputId = inputId;
        this.s3FileName = s3FileName;
        this.recommendedWidth = recommendedWidth;
        this.recommendedHeight = recommendedHeight;
    }
}
