import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Match } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MatchingService {
  constructor(private readonly http: HttpClient) { }

  private endpointUrl = '/api/matching';

  matchEvent(eventId: number): Observable<Match[]> {
    return this.http.get<Match[]>(this.endpointUrl + `/startMatching/${eventId}`);
  }
}
