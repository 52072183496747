import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Participant} from "../../models/participant";

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  constructor(private readonly http: HttpClient) { }

  private endpointUrl = '/api/participants';

  getByEmail(email: string): Observable<Participant> {
    const params = new HttpParams().set('email', email);
    return this.http.get<Participant>(this.endpointUrl, {params});
  }

  createParticipant(participant: Participant): Observable<Participant> {
    return this.http.post<Participant>(this.endpointUrl, participant);
  }

  updateParticipant(participant: Participant): Observable<Participant> {
    return this.http.put<Participant>(this.endpointUrl, participant);
  }

  getParticipantCountTotal(): Observable<number> {
    return this.http.get<number>(this.endpointUrl + `/count`);
  }
}
