import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  submitButtonName?: string;
  cancelButtonName?: string;
  title?: string
  field?: {
    label: string,
    value: string
  }
}

@Component({
  selector: 'confirm',
  templateUrl: 'confirm.component.html',
})
export class Confirm {
  constructor(
    public dialogRef: MatDialogRef<Confirm>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
