import {Directive, ElementRef, Input, OnInit, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from 'src/app/shared/services';
import {UserRole} from "../../interfaces";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Directive({
  selector: '[accessControl]',
  providers: [AuthenticationService],
})
export class UserAccessControlDirective implements OnInit {
  private requiredRoles: String[];

  constructor(private elementRef: ElementRef, private vcr: ViewContainerRef, private readonly authenticationService: AuthenticationService) {
  }

  @Input() set accessControl(requiredRoles: UserRole[]) {
    if (requiredRoles) {
      this.requiredRoles = requiredRoles;
    }
  }

  ngOnInit(): void {
    this.checkAccess().subscribe(_hasRequiredRoles => {
      if (!_hasRequiredRoles) {
        this.elementRef.nativeElement.remove();
      }
    })
  }

  checkAccess(): Observable<boolean> {
    let _requiredRoles = this.requiredRoles as UserRole[];
    return this.authenticationService.getRoles().pipe(
      map(roles => {
        return _requiredRoles.some(role => roles.includes(role));
      })
    );
  }
}
