import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faCalendarCheck, faCoffee, faListAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { forkJoin, Observable } from 'rxjs';
import { AuthenticationService, EventService, MeetupService, ParticipantService, StaticContentService, SubscriptionService } from 'src/app/shared/services';

@Component({
  selector: 'rc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  numberOfSubscriptions: number;
  numberOfCompletedMeetups: number;
  numberOfProgramParticipants: number;
  numberOfEvents: number;

  coffeeIcon = faCoffee;
  listIcon = faListAlt;
  peopleIcon = faUsers;
  calendarIcon = faCalendarCheck;
  slackIcon = faSlack;

  backgroundImageUrl = this.staticContentService.getStaticContentUrl(StaticContentService.HOME_BACKGROUND_S3_FILE_NAME);

  constructor(private readonly subscriptionService: SubscriptionService,
    private readonly meetupService: MeetupService,
    private readonly eventService: EventService,
    private readonly participantService: ParticipantService,
    private readonly authenticationService: AuthenticationService,
    private readonly staticContentService: StaticContentService,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.staticContentService.staticContentUrlChanged$.subscribe(() => {
      this.backgroundImageUrl = this.staticContentService.getStaticContentUrl(StaticContentService.HOME_BACKGROUND_S3_FILE_NAME);
    })
    console.log("backgroundImageUr: ", this.backgroundImageUrl);
    this.getSystemStatistics().subscribe(([subscriptionCount, completedMeetupCount, participantCount, eventCount]) => {
      this.numberOfSubscriptions = subscriptionCount;
      this.numberOfCompletedMeetups = completedMeetupCount;
      this.numberOfProgramParticipants = participantCount;
      this.numberOfEvents = eventCount;
    });
  }

  getSystemStatistics(): Observable<[number, number, number, number]> {
    const numberOfSubscriptions = this.subscriptionService.getSubscriptionCountTotal();
    const numberOfCompletedMeetups = this.meetupService.getCompleteMeetupsTotal();
    const numberOfProgramParticipants = this.participantService.getParticipantCountTotal();
    const numberOfEvents = this.eventService.getEventCount();
    return forkJoin([numberOfSubscriptions, numberOfCompletedMeetups, numberOfProgramParticipants, numberOfEvents]);
  }

  login(): void {
    this.authenticationService.isAuthenticated().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.router.navigateByUrl('/user');
      } else {
        this.authenticationService.cognitoSignIn();
      }
    });
  }
}
