import {Organization} from "./organization";

export class User {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  participantId: number;
  locationId: number;
  about: string;
  roles: string[];
  isAdmin: boolean = false;
  volunteer: boolean = false;
  location: string;
  department: string;
  role: string;
  companyDuration: string;
  contactOption: string = "";
  organizationId: number = 1;
  organization: Organization;

  constructor(username: string, firstName: string, lastName: string, email: string, organizationId?: number) {
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.organizationId = organizationId;
  }

  hasRequiredDetails(): boolean {
    return !!this.firstName && !!this.lastName && !!this.organizationId;
  }

}
