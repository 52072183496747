import { Component } from '@angular/core';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { StaticContentService } from 'src/app/shared/services';

@Component({
  selector: 'rc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(private readonly staticContentService: StaticContentService) {}
  teamsIcon: string;
  footerCupImg: string;
  footerEPGlogo: string;
  slackIcon = faSlack;
  ngOnInit(): void {
    this.teamsIcon = this.staticContentService.getStaticContentUrl('teams_icon.svg');
    this.footerCupImg = this.staticContentService.getStaticContentUrl('cup-of-coffee-footer.svg');
    this.footerEPGlogo = this.staticContentService.getStaticContentUrl('epg-logo.svg');
  }

}
