import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { SupportEmail } from 'src/app/shared/interfaces/support-email';
import { SupportTicket } from 'src/app/shared/interfaces/support-ticket';
import { Organization } from 'src/app/shared/models/organization';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(private readonly http: HttpClient) { }

  private endpointUrl = '/api/support';

  async getSupportEmails(organization?: Organization): Promise<any> {
    if (organization && organization.organizationId) {
      return this.http.get<SupportEmail[]>(`${this.endpointUrl}?organizationId=${organization.organizationId}`);
    } else return this.http.get<SupportEmail[]>(this.endpointUrl);
  }

  removeSupportEmail(id: number): Observable<String> {
    return this.http.post<string>(this.endpointUrl + '/remove', id);
  }

  addSupportEmail(email: SupportEmail, organization?: Organization): Observable<SupportEmail> {
    if (organization && organization.organizationId) {
      return this.http.post<SupportEmail>(`${this.endpointUrl}/add?organizationId=${organization.organizationId}`, email);
    } else return this.http.post<SupportEmail>(this.endpointUrl + '/add', email);
  }

  sendSupportRequest(ticket: SupportTicket, attachment: File): void {
    const data = new FormData();
    const ticketBlob = new Blob([JSON.stringify(ticket)], { type: 'application/json' });
    data.append('supportAttachment', attachment);
    data.append('supportTicket', ticketBlob);
    this.http.post<SupportTicket>(this.endpointUrl + '/send-ticket', data).subscribe();
  }
}
